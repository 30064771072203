import type { User } from '~/interfaces';

export function useMentionFunctions() {
  const renderTextWithMentionElements = (usersToMention: Ref<User[]>, text: string) => {
    if (!usersToMention.value.length) {
      return text;
    }

    let updatedText = text;

    usersToMention.value.forEach((user) => {
      const mentionPattern = new RegExp(`@${user.username}`, 'g');
      const replacement = `<span class="text-primary_500 cursor-pointer mention-span-element" data-id="${user.id}">@${user.username}</span>`;
      updatedText = updatedText.replace(mentionPattern, replacement);
    });

    return updatedText;
  };

  const addClickEventsToMentionElements = () => {
    const router = useRouter();
    const localePath = useLocalePath();
    const mentionElements = document.querySelectorAll('.mention-span-element');
    mentionElements.forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        const target = event.currentTarget as HTMLElement;
        router.push(localePath(`/user-posts/${target.dataset.id}/all`));
      });
    });
  };

  return {
    renderTextWithMentionElements,
    addClickEventsToMentionElements
  };
}
