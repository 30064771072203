<template>
  <ClientOnly>
    <Mentionable
      ref="mentionableRef"
      insert-space
      :keys="['@']"
      :items="usersToDisplay"
      @search="searchToMention($event)"
      @apply="handleApply"
    >
      <slot />
      <template #no-result>
        <p class="text-primary_500 text-[14px] font-medium leading-[150%] tracking-[-0.28px]">
          {{ determineDisplayMessage }}
        </p>
      </template>
      <template #item="{ item }">
        <div class="flex items-center w-full py-2 user px-3 min-w-[144px]">
          <UserPicture gap="gap-2" :user="item.wholeUser" />
        </div>
      </template>
    </Mentionable>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { Mentionable } from 'vue-mention';
import 'floating-vue/dist/style.css';
import type { User } from '~/interfaces';
import { userRepository } from '~/repositories/userRepository';
import type { PropType } from 'vue';

type MentionType = 'post' | 'comment';

const props = defineProps({
  mentionType: {
    required: true,
    type: String as PropType<MentionType>
  }
});

const emits = defineEmits(['user-mentioned', 'user-removed', 'all-mentioned']);

const { $api } = useNuxtApp();
const { t } = useI18n();
const userRepo = userRepository($api);
const { debounce } = useGlobalFunctions();

const loading = ref(false);
const usersToMention = ref<User[]>([]);
const usersToSearch = ref<User[]>([]);
const keywordRef = ref('');
const mentionableRef = ref<any>(null);
const usersToDisplay = computed(() => {
  return usersToSearch.value.map((user) => ({
    value: user.username,
    username: user.username,
    wholeUser: user
  }));
});

onMounted(async () => {
  await nextTick(); // mora ovako jer je client only
  getTextElementAndAttachListener();
});

const getTextElementAndAttachListener = () => {
  const textElement =
    mentionableRef.value.$el.querySelector('input') ||
    mentionableRef.value.$el.querySelector('textarea');
  if (textElement) {
    textElement.addEventListener('input', () => {
      checkIfMentionStillExists(textElement.value);
    });
  }
};

const searchToMention = debounce(async (keyword: string) => {
  keywordRef.value = keyword;
  let response = null;
  loading.value = true;
  if (keyword && keyword.length > 1) {
    response = await userRepo.searchUser(keyword);
  }
  if (response) {
    usersToSearch.value = response.formattedUsers;
  } else {
    usersToSearch.value = [];
  }
  loading.value = false;
}, 300);

const handleApply = (userItem: any) => {
  usersToMention.value.push(userItem.wholeUser);
  emits('user-mentioned', userItem.wholeUser);
  usersToSearch.value = [];
  keywordRef.value = '';
};

const determineDisplayMessage = computed(() => {
  return loading.value
    ? t('loadingUsers')
    : keywordRef.value.length < 2
      ? t('searchTerms')
      : usersToDisplay.value.length === 0
        ? t('usersNotFound')
        : '';
});

const mentionUsers = async (referenceId: number) => {
  if (usersToMention.value.length) {
    const userIds = usersToMention.value.map((user) => user.id);
    try {
      emits('all-mentioned');
      await userRepo.mentionUsers(userIds, props.mentionType, referenceId);
      usersToMention.value = [];
    } catch (error) {
      console.error('Failed to mention users:', error);
    }
  }
};

const checkIfMentionStillExists = (text: string) => {
  const usersToMentionCopy = usersToMention.value;
  usersToMentionCopy.forEach((user, index) => {
    if (!text.includes(`@${user.username}`)) {
      usersToMention.value.splice(index, 1);
      emits('user-removed', index);
    }
  });
};

defineExpose({
  mentionUsers,
  getTextElementAndAttachListener
});
</script>

<style>
.mention-selected .user {
  background: #0e1405;
  border-radius: 4px;
}
.v-popper--theme-dropdown .v-popper__inner {
  padding: 16px 12px;
  border-radius: 8px 8px 0px 0px;
  background: #1c1f21;
  border: 0px;
}
.v-popper__wrapper {
  background: #1c1f21;
}

.v-popper__arrow-container {
  display: none;
}

.mentionable {
  width: 100%;
}
</style>
