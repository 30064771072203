<template>
  <div class="flex flex-col pb-4 bg-neutral_500 rounded-[8px] px-10 py-6 mt-4">
    <div class="flex items-center justify-center h-8">
      <!-- <ButtonComponent type="text" :text="`${$t('closeComments')}`" @click="showComments = false" /> -->
      <!-- <ClientOnly>
        <ion-icon
          name="close-outline"
          class="text-[24px] cursor-pointer"
          @click="showComments = false"
        />
      </ClientOnly> -->
      <ButtonComponent
        type="text"
        :text="`${$t('closeComments')}`"
        @click="emits('close-comments')"
      />
    </div>
    <UserMention
      v-if="showTextArea"
      ref="userMentionComponent"
      mention-type="comment"
      @user-mentioned="handleUserMentioned"
      @user-removed="handleUserRemoved"
      @all-mentioned="handleAllMentioned"
    >
      <LeaveComment
        ref="leaveComment"
        v-model="newCommentText"
        class="my-2"
        :is-doing-service="isLoading"
        @create-comment="createNewComment"
        @hide-textarea="showTextArea = false"
      />
    </UserMention>
    <div
      v-else
      class="h-[48px] flex items-center justify-start p-6 bg-primary_900 my-[10px] rounded-[4px] cursor-pointer"
      @click="handleShowTextArea"
    >
      <p class="text-[16px] font-medium leading-[150%] text-base_white">{{ $t('comment') }}</p>
    </div>
    <div v-for="(comment, index) in comments" :key="`comment-${comment.id}`">
      <Comment
        :comment="comment"
        @delete-comment="handleDeleteComment(comment, index)"
        @created-reply="handleReplyAdded"
        @show-replies="handleShowReplies"
        @reply-opened="showTextArea = false"
      />
      <template v-if="comment.childComments?.length && comment.showReplies">
        <div
          v-for="(reply, indexOfReplies) in comment.childComments"
          :key="`reply-${reply.id}`"
          class="ml-6 mb-6 mt-2"
        >
          <Comment :comment="reply" @delete-comment="handleDeleteComment(reply, indexOfReplies)" />
        </div>
      </template>
      <!-- <div v-if="index != 2" class="h-px w-full bg-primary_800 my-2" /> -->
    </div>
    <ButtonComponent
      v-if="nextCursor"
      :text="$t('loadMoreComments')"
      size="md"
      type="outlined"
      :cropped="true"
      class="mt-2 mx-auto"
      @click="loadMoreComments"
    />
  </div>
</template>
<script lang="ts" setup>
import { postRepository } from '~/repositories/postRepository';
import type { Post, Comment, User } from '~/interfaces';

const props = defineProps({
  post: {
    type: Object as PropType<Post>,
    required: true
  },
  totalComments: {
    type: Number,
    required: true
  }
});
const emits = defineEmits(['close-comments', 'comment-added', 'comment-deleted']);
const { $api } = useNuxtApp();
const postRepo = postRepository($api);

const { renderTextWithMentionElements } = useMentionFunctions();

const comments = ref<Comment[]>([]);
const showTextArea = ref(false);
const isLoading = ref(false);
const leaveComment = ref<any | null>(null);
const userMentionComponent = ref<any>(null);
const usersToMention = ref<User[]>([]);

const newCommentText = ref('');
const nextCursor = ref('');

const getCommentsForThisPost = async () => {
  try {
    const response = await postRepo.getPostComments(props.post.id);
    comments.value = response.comments.map((comment: any) => ({
      ...comment,
      showReplies: false
    }));
    nextCursor.value = response.nextCursor;
  } catch (err) {
    console.log(err);
  }
};
onMounted(async () => {
  if (props.totalComments) {
    await getCommentsForThisPost();
  }
});
const loadMoreComments = async () => {
  const response = await postRepo.getPostComments(props.post.id, nextCursor.value);
  const additionalComments = response.comments.map((comment: any) => ({
    ...comment,
    showReplies: false
  }));
  comments.value.push(...additionalComments);
  nextCursor.value = response.nextCursor;
};
const createNewComment = async () => {
  try {
    isLoading.value = true;
    const newComment = await postRepo.createComment(
      renderTextWithMentionElements(usersToMention, newCommentText.value),
      props.post.id
    );
    isLoading.value = false;
    emits('comment-added');
    newComment.showReplies = false;
    newCommentText.value = '';
    showTextArea.value = false;
    comments.value.unshift(newComment);
    if (newComment && userMentionComponent.value) {
      userMentionComponent.value.mentionUsers(newComment.postId);
    }
  } catch (err) {
    isLoading.value = false;
    console.log(err);
  }
};
const handleReplyAdded = (newReply: Comment) => {
  const parentComment = comments.value.find((c) => c.id === newReply.parentCommentId);
  if (parentComment) {
    if (!parentComment.childComments) {
      parentComment.childComments = [];
    }
    parentComment.childComments.push(newReply);
    emits('comment-added');
  }
};

const handleShowReplies = (newValue: boolean, id: number) => {
  const commentToShowReplies = comments.value.find((p) => p.id == id);
  if (commentToShowReplies) {
    commentToShowReplies.showReplies = newValue;
  }
};

const handleDeleteComment = (comment: Comment, index: number) => {
  if (comment.parentCommentId) {
    const parentComment = comments.value.find((c) => c.id == comment.parentCommentId);
    parentComment?.childComments?.splice(index, 1);
  } else {
    comments.value.splice(index, 1);
  }
  emits('comment-deleted', comment);
};

const handleUserMentioned = (userToMention: User) => {
  usersToMention.value.push(userToMention);
};
const handleUserRemoved = (index: number) => {
  usersToMention.value.splice(index, 1);
};
const handleAllMentioned = () => {
  usersToMention.value = [];
};
const handleShowTextArea = () => {
  showTextArea.value = true;
  nextTick(() => {
    if (leaveComment.value) leaveComment.value.focusTextarea();
  });
};
</script>
