<template>
  <TransitionRoot as="template" :show="props.isOpen">
    <Dialog class="relative z-10" @close="emits('close-dialog')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral_950 bg-opacity-30 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden shadow-xl transition-all w-full max-w-[660px] px-4 sm:px-6 py-3 rounded-[12px] bg-primary_950"
            >
              <form @submit.prevent="editPost">
                <div class="flex flex-col gap-2">
                  <div class="flex items-center h-10 justify-between">
                    <p
                      class="text-[12px] font-bold text-base_white uppercase tracking-[2.4px] leading-[150%]"
                    >
                      {{ $t('editPost') }}
                    </p>
                    <ion-icon
                      class="text-[16px] text-base_white cursor-pointer"
                      name="close-outline"
                      @click="emits('close-dialog')"
                    />
                  </div>
                  <div class="flex pt-4 sm:pt-6 items-center gap-2">
                    <UToggle v-model="isPostTicket" color="primary" />
                    <p
                      class="text-neutral_50 text-[14px] font-medium leading-[150%] tracking-[-0.28px]"
                    >
                      {{ $t('isTicket') }}
                    </p>
                  </div>
                  <div class="h-px w-full bg-primary_800" />
                  <div class="text-left">
                    <UserMention
                      ref="userMentionComponent"
                      mention-type="post"
                      @user-mentioned="handleUserMentioned"
                      @user-removed="handleUserRemoved"
                      @all-mentioned="handleAllMentioned"
                    >
                      <InputTextArea
                        v-model="postDescription"
                        :error="v$.postDescription.$error"
                        :error-messages="v$.postDescription.$errors"
                        :placeholder="$t('postDescription')"
                      />
                    </UserMention>
                  </div>
                  <div class="flex items-center justify-center">
                    <ButtonComponent
                      :form-element="true"
                      :is-loading="isLoading"
                      class="mt-2 sm:mt-6"
                      :text="$t('share')"
                    />
                  </div>
                </div>
              </form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import type { Post, User } from '~/interfaces';
import { postRepository } from '~/repositories/postRepository';

const emits = defineEmits(['close-dialog', 'post-updated']);
const { t } = useI18n();
const { $api } = useNuxtApp();
const postRepo = postRepository($api);
const props = defineProps({
  post: {
    type: Object as PropType<Post>,
    required: true
  },
  isOpen: {
    type: Boolean,
    required: true
  }
});
const { renderTextWithMentionElements, addClickEventsToMentionElements } = useMentionFunctions();
const remvoveTagsInitilay = (htmlString: string) => {
  return htmlString.replace(/<\/?[^>]+(>|$)/g, '');
};
const captureOldUsersFromTheText = (htmlString: string) => {
  const mentionRegex = /<span[^>]+data-id="([^"]+)"[^>]*>@([^<]+)<\/span>/g;
  let match;

  // while da bi gledao za svakog user-a u tekstu
  while ((match = mentionRegex.exec(htmlString)) !== null) {
    const id = match[1]; // ovo hvata id iz data
    const username = match[2]; // ovo hvata username posle @
    oldMentionedUsers.value.push({ id, username } as User);
  }
};
const isPostTicket = ref(props.post.isTicket);
const isLoading = ref(false);
const postDescription = ref(remvoveTagsInitilay(props.post.postDescription));
const userMentionComponent = ref<any>('');
const newUsersToMention = ref<User[]>([]);
const oldMentionedUsers = ref<User[]>([]);
const rules = {
  postDescription: {
    required: helpers.withMessage(`${t('postDescriptionRequired')}`, required)
  }
};
const v$ = useVuelidate(rules, { postDescription }, { $scope: false });
onMounted(() => {
  captureOldUsersFromTheText(props.post.postDescription);
});
const editPost = async () => {
  const isFormValid = await v$.value.$validate();
  if (isFormValid) {
    const allUsersToMention = ref<User[]>([...oldMentionedUsers.value, ...newUsersToMention.value]);
    isLoading.value = true;
    const response = await postRepo.editPost(
      props.post.id,
      renderTextWithMentionElements(allUsersToMention, postDescription.value), // ovde je all da rerenderuje ponovo spanove za sve
      isPostTicket.value
    );
    isLoading.value = false;
    if (response && userMentionComponent.value) {
      userMentionComponent.value.mentionUsers(response.updatedPost.id); // ovde ce se mentionovati samo dodati useri, nece stari ponovo
    }
    emits('close-dialog');
    emits('post-updated', response.updatedPost);
    nextTick(() => {
      addClickEventsToMentionElements();
    });
  }
};

const handleUserMentioned = (userToMention: User) => {
  newUsersToMention.value.push(userToMention);
};
const handleUserRemoved = (index: number) => {
  newUsersToMention.value.splice(index, 1);
};
const handleAllMentioned = () => {
  newUsersToMention.value = [];
};

watch(
  () => postDescription.value,
  (newValue) => {
    const oldMentionedUsersCopy = oldMentionedUsers.value;
    oldMentionedUsersCopy.forEach((user, index) => {
      if (!newValue.includes(user.username)) {
        oldMentionedUsers.value.splice(index, 1);
      }
    });
  }
);
</script>
